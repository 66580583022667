import React from "react";
import { Link } from "react-router-dom";
import "../Styles/Button/Button.css";

function Button({ text, isActive, hasFunction, link, onClickFunction }) {
  return (
    <div>
      {hasFunction ? (
        <div
          onClick={onClickFunction}
          className={isActive ? "button_wrapper active" : "button_wrapper"}
        >
          <p>{text}</p>
        </div>
      ) : (
        <Link to={link} style={{ textDecoration: "none" }}>
          <div className="button_wrapper">
            <p>{text}</p>
          </div>
        </Link>
      )}
    </div>
  );
}

export default Button;
