import React from "react";
import { Link } from "react-router-dom"; // Import Link
import Navbar from "../../Components/Navbar";
import "../../Styles/Packs/PackSection.css";

function PackSection() {
  return (
    <div className="pack_container">
      <Navbar />
      <div className="pack_display">
        <Link to="/packs/1">
          <img
            className="pack_image pack1"
            src="https://www.serebii.net/tcgpocket/geneticapex/mewtwo.jpg"
            alt="Mewtwo Pack"
          />
        </Link>
        <Link to="/packs/2">
          <img
            className="pack_image pack2"
            src="https://www.serebii.net/tcgpocket/geneticapex/pikachu.jpg"
            alt="Pikachu Pack"
          />
        </Link>
        <Link to="/packs/3">
          <img
            className="pack_image pack3"
            src="https://www.serebii.net/tcgpocket/geneticapex/charizard.jpg"
            alt="Charizard Pack"
          />
        </Link>
      </div>
    </div>
  );
}

export default PackSection;
