import React from "react";
import { Link } from "react-router-dom"; // Import Link
import "../Styles/Navbar/Navbar.css";
import NavbarItem from "./NavbarItem";
import Logo from "../Assets/navbar_logo.png";

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navbar_container">
        <Link to="/" style={{ textDecoration: "none", marginBottom: "-10px" }}>
          <img src={Logo} alt="Pocket-TCG" className="navbar_logo" />
        </Link>
        <ul className="navbar_menu">
          <Link to="/cards" style={{ textDecoration: "none" }}>
            <NavbarItem name="CARDS" />
          </Link>
          <Link to="/packs" style={{ textDecoration: "none" }}>
            <NavbarItem name="PACKS" />
          </Link>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
