import React from "react";
import "../Styles/Section/Section.css";
import Button from "./Button";

function Section({ ...props }) {
  return (
    <section>
      <div className="section_wrapper" id={props.section_id}>
        <div className="section_container">
          <video
            className="section_gif"
            src={props.section_gif}
            autoPlay={true}
            loop={true}
          />
          <div className="section_info">
            <p className="section_title">{props.section_title}</p>
            <p className="section_info_text">{props.text1}</p>
            <p className="section_info_text">{props.text2}</p>
            <p className="section_info_text">{props.text3}</p>
            <Button
              text={props.btn_text}
              hasFunction={props.btn_has_function}
              link={props.btn_link}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
export default Section;
