import React from "react";
// import HomeSection from "./HomeSection";
import Button from "../../Components/Button";
import Section from "../../Components/Section";
import CardsVideo from "../../Assets/videos/cards.mp4";
import PacksVideo from "../../Assets/videos/packs.mp4";
import "../../Styles/Home/Home.css";

function Home() {
  return (
    <div className="home_container">
      <section>
        <div className="home_wrapper">
          <div className="home_container">
            <div className="home_text">
              <div className="welcome_message">
                <p>Welcome to Pokémon TCG Pocket Simulator</p>
              </div>
            </div>
            <div className="home_button">
              <Button
                text={"Get started"}
                hasFunction={false}
                link={"#cards"}
              />
            </div>
          </div>
        </div>
      </section>
      <Section
        section_id={"cards"}
        section_gif={CardsVideo}
        section_title={"Card showcase"}
        text1={"Every single card in the game showcased"}
        text2={"Sort the cards by pack"}
        text3={"Click on card to see more details"}
        btn_text={"Go to cards"}
        btn_hasFunction={false}
        btn_link={"/cards"}
      />
      <Section
        section_id={"packs"}
        section_gif={PacksVideo}
        section_title={"Pack opening"}
        text1={"Choose between one of three packs"}
        text2={"Each pack contains exclusive cards"}
        text3={"Opening the pack showcases five cards"}
        btn_text={"Go to packs"}
        btn_hasFunction={false}
        btn_link={"/packs"}
      />
    </div>
  );
}
export default Home;
