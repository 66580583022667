import React from "react";
import "../Styles/Card/Card.css";

function Card({ card }) {
  if (!card) return null;
  const { img, card_name } = card;

  return (
    <div className="card_container">
      <img src={img} alt={card_name} />
    </div>
  );
}
export default Card;
