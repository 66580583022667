import React, { useEffect, useState } from "react";
import Card from "../../Components/Card";
import { Link } from "react-router-dom";
import "../../Styles/Cards/CardsSection.css";
import Navbar from "../../Components/Navbar";
import Button from "../../Components/Button";

function CardsSection() {
  // TODO:
  // 2. 3d move card
  // 3. holo effect on holo cards rarities 2,3,5,6,7,8
  // 4. card section div redo, w: 20,60,20

  const [cards, setCards] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const [selectedPack, setSelectedPack] = useState("all");

  useEffect(() => {
    fetch("https://mainotas.com/api/cards")
      .then((response) => response.json())
      .then((data) => {
        setCards(data);
      })
      .catch((error) => console.error("Error fetching cards: ", error));
  }, []);

  useEffect(() => {
    if (selectedPack === "all") {
      setFilteredCards(cards);
    } else {
      setFilteredCards(cards.filter((card) => card.pack_id === selectedPack));
    }
  }, [selectedPack, cards]);

  // Handle pack selection
  const handlePackSelect = (packId) => {
    setSelectedPack(packId);
  };

  return (
    <>
      <Navbar />
      <div className="cards_section_wrapper">
        <div className="card_filter_buttons">
          <p>Sort by pack:</p>
          <Button
            text={"All"}
            isActive={selectedPack === "all"}
            hasFunction={true}
            onClickFunction={() => handlePackSelect("all")}
          />
          <Button
            text={"Mewtwo"}
            isActive={selectedPack === 1}
            hasFunction={true}
            onClickFunction={() => handlePackSelect(1)}
          />
          <Button
            text={"Pikachu"}
            isActive={selectedPack === 2}
            hasFunction={true}
            onClickFunction={() => handlePackSelect(2)}
          />
          <Button
            text={"Charizard"}
            isActive={selectedPack === 3}
            hasFunction={true}
            onClickFunction={() => handlePackSelect(3)}
          />
        </div>
        <div className="cards_section_container">
          {console.log(cards)}
          {filteredCards.map((card, index) => (
            <Link to={`/${card.card_name}/${card.set_nr}`} key={index}>
              <Card card={card} />
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}
export default CardsSection;
