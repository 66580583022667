import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import ScrollToHashElement from "@cascadia-code/scroll-to-hash-element";
import CardsSection from "./Pages/Cards/CardsSection";
import CardDetails from "./Pages/Cards/CardDetails";
import PackDetails from "./Pages/Packs/PackDetails";
import PackSection from "./Pages/Packs/PackSection";
import Home from "./Pages/Home/Home";
import initializeAnalytics from "./Components/Analytics";

function App() {
  useEffect(() => {
    initializeAnalytics();
  }, []);

  return (
    <>
      <ScrollToHashElement />
      <Routes>
        <Route path="/packs" element={<PackSection />} />
        <Route path="/packs/:packId" element={<PackDetails />} />
        <Route path="/cards" element={<CardsSection />} />
        <Route path="/:cardName/:setNr" element={<CardDetails />} />
        <Route path="/" element={<Home />} />
        {/* <HeroSection /> */}
        {/* <CardsSection /> */}
      </Routes>
    </>
  );
}

export default App;
