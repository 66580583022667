import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Card from "../../Components/Card";
import "../../Styles/Packs/PackDetails.css";
import Navbar from "../../Components/Navbar";
import { useSwipeable } from "react-swipeable";
import Button from "../../Components/Button";

function PackDetails() {
  const { packId } = useParams();
  const [cards, setCards] = useState([]);
  const [opening, setOpening] = useState(false);
  const [curCardIndex, setCurCardIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState("");
  const [textAnimationClass, setTextAnimationClass] = useState("");

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleOpening(),
    trackMouse: true,
  });

  const handleOpenPack = () => {
    console.log("Opening pack with ID:", packId);
    setCurCardIndex(0);
    setAnimationClass("show_card");
    fetch(
      `https://mainotas.com/api/packs/${packId}/cards?nocache=${new Date().getTime()}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched cards: ", data);
        setCards(data);
        setOpening(true);
      })
      .catch((error) => {
        console.error("Error fetching pack cards: ", error);
        alert("Failed to open the pack. Please try again.");
      });
  };

  const handleOpening = () => {
    if (curCardIndex < cards.length - 1) {
      setAnimationClass("slide_out_left");
      if (textAnimationClass === "") {
        setTextAnimationClass("fade_out");
      }

      setTimeout(() => {
        setCurCardIndex(curCardIndex + 1);
        setAnimationClass("slide_in_right");

        setTimeout(() => {
          setAnimationClass("show_card");
        }, 100);
      }, 500);
    } else {
      setAnimationClass("slide_out_left");
      setTimeout(() => {
        setOpening(false);
        setTextAnimationClass("");
      }, 500);
    }
  };

  return (
    <div>
      <Navbar />
      <h2>Pack {packId}</h2>
      <div className="pack_cards_container">
        {cards.length > 0 && opening ? (
          <div
            {...swipeHandlers}
            key={curCardIndex}
            className={`pack_card_item_opening ${animationClass}`}
          >
            <button className="card_swiper fade_in" onClick={handleOpening}>
              <Card card={cards[curCardIndex]} />
            </button>
          </div>
        ) : (
          cards.map((card, index) => (
            <div className="pack_card_container fade_in">
              <div key={index} className="pack_card_item">
                <Card card={card} />
              </div>
            </div>
          ))
        )}
      </div>
      <div className="opening_container">
        {opening ? (
          <div className="card_opening_help_container">
            <p className={`card_opening_help_text ${textAnimationClass}`}>
              Swipe left or click to open
            </p>
          </div>
        ) : (
          <Button
            text={"Open pack"}
            hasFunction={true}
            onClickFunction={handleOpenPack}
          />
        )}
      </div>
    </div>
  );
}

export default PackDetails;
