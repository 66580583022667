import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "../../Styles/Cards/CardDetails.css";
import Navbar from "../../Components/Navbar";

function CardDetails() {
  const { cardName, setNr } = useParams();
  const [card, setCard] = useState(null);

  const cardRef = useRef(null);
  useEffect(() => {
    fetch(`https://mainotas.com/api/card/${cardName}/${setNr}`)
      .then((response) => response.json())
      .then((data) => setCard(data))
      .catch((error) => console.error("Error fetching card details: ", error));
  }, [cardName, setNr]);

  useEffect(() => {
    if (cardRef.current) {
      // calculate rotation based on mouse position
      const handleMouseMove = (event) => {
        console.log("Mouse moved");
        const rect = card_container.getBoundingClientRect();
        const x = (event.clientX - rect.left) / rect.width;
        const y = (event.clientY - rect.top) / rect.height;

        const rotationX = (y - 0.5) * 50;
        const rotationY = (x - 0.5) * 50;

        cardRef.current.style.transform = `rotateX(${rotationX}deg) rotateY(${rotationY}deg) `;
        cardRef.current.style.transition = `0.1s`;
      };

      // reset to original position on mouse leave
      const handleMouseLeave = () => {
        cardRef.current.style.transform = `rotateX(0deg) rotateY(0deg)`;
        cardRef.current.style.transition = `1s ease-out`;
      };
      const card_container = cardRef.current;
      card_container.addEventListener("mousemove", handleMouseMove);
      card_container.addEventListener("mouseleave", handleMouseLeave);
    }
  }, [card]);

  if (!card) {
    return <div>Loading</div>;
  }
  if (card.error) {
    return <div>{card.error}</div>;
  }
  return (
    <div className="card_details_wrapper">
      <Navbar />
      <div className="card_details_container">
        <h2 className="card_name">{card.card_name}</h2>
        <div className="card_display">
          <a href={card.img} target="__blank">
            <img ref={cardRef} src={card.img} alt={card.card_name} />
          </a>
        </div>
        <div className="card_detail_text">
          <p>Set Nr.: {card.set_nr} / 226</p>
          <p>Pack ID: {card.pack_id}</p>
        </div>
      </div>
    </div>
  );
}

export default CardDetails;
